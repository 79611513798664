import React, { Component, Fragment } from "react"
import {EuiHideFor} from '@elastic/eui'

import {
  ReactiveBase,
  DataSearch,
  SelectedFilters,
  ReactiveList,
  SingleList,
  MultiList,
  ResultList,
} from "@appbaseio/reactivesearch"

import { _connectionString, _index_wiki } from './ElasticParam'

const { ResultListWrapper } = ReactiveList

class ReactiveSearch extends Component {
  render() {
    return (
      <Fragment>
        <ReactiveBase
          url={_connectionString}
          app={_index_wiki}
          theme={{
            colors: {
              primaryColor: "#bebebe",
              textColor: "#7e7e7e"
            },
          }}
        >
          <DataSearch
            componentId="q"
            placeholder="INSTAR Search Assistant"
            react={{
              and: ["chapter_filter", "tag_filter", "type_filter", "series_filter"],
            }}
            dataField={["title", "tags", "abstract", "description"]}
            fieldWeights={[8, 10, 5, 3]}
            searchOperators={false}
            autosuggest={true}
            highlight={true}
            defaultSuggestions={[
              { label: "First Steps", value: "first steps" },
              { label: "Installation", value: "installation" },
              { label: "Web User Interface", value: "web user interface" },
              { label: "InstarVision", value: "instarvision" },
            ]}
            fuzziness={1}
            debounce={100}
            showFilter={true}
            filterLabel="Filter"
            URLParams={true}
          />
          <SelectedFilters />
          <div style={{ display: 'flex', marginTop: 20 }}>
            <EuiHideFor sizes={['xs', 's', 'm']}>
              <div style={{ flexDirection: 'row' }}>
                  <SingleList
                    componentId="chapter_filter"
                    dataField="chapter.raw"
                    title="Chapters"
                    size={100}
                    sortBy="count"
                    defaultValue="All Chapters"
                    selectAllLabel="All Chapters"
                    showRadio={true}
                    showCount={true}
                    showSearch={false}
                    react={{
                      and: ["q"],
                    }}
                    showFilter={true}
                    filterLabel="Filter"
                    URLParams={true}
                    loader="Loading ..."
                  />

                  <hr />

                  <SingleList
                    componentId="type_filter"
                    dataField="type.raw"
                    title="Type"
                    size={10}
                    sortBy="count"
                    defaultValue="All Types"
                    selectAllLabel="All Types"
                    showRadio={true}
                    showCount={true}
                    showSearch={false}
                    react={{
                      and: ["q"],
                    }}
                    showFilter={true}
                    filterLabel="Filter"
                    URLParams={true}
                    loader="Loading ..."
                  />

                  <hr />

                  <SingleList
                    componentId="series_filter"
                    dataField="series.raw"
                    title="Serie"
                    size={10}
                    sortBy="count"
                    defaultValue="All Series"
                    selectAllLabel="All Series"
                    showRadio={true}
                    showCount={true}
                    showSearch={false}
                    react={{
                      and: ["q"],
                    }}
                    showFilter={true}
                    filterLabel="Filter"
                    URLParams={true}
                    loader="Loading ..."
                  />

                  <hr />

                  <MultiList
                    componentId="tag_filter"
                    dataField="tags.raw"
                    title="Tags"
                    size={25}
                    react={{
                      and: ["q"],
                    }}
                    showSearch={false}
                    URLParams={true}
                    loader="Loading ..."
                  />
                </div>
              </EuiHideFor>
            <ReactiveList
              from={0}
              react={{
                and: ["q", "chapter_filter", "tag_filter", "type_filter", "series_filter"],
              }}
              componentId="SearchResult"
              dataField="chapter.raw"
              stream={true}
              pagination={true}
              paginationAt="bottom"
              size={11}
              loader="Loading Results.."
              showResultStats={true}
              scrollOnChange={true}
            >
              {({ data }) => (
                <div style={{ marginLeft: 20, padding: 10, maxWidth: 600 }}>
                  <ResultListWrapper>
                    {data.map((item) => (
                      <div style={{ background: 'white', margin: '10px 0', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)' }} key={item._id}>
                        <ResultList href={'/en'+item.sublink1}>
                            <ResultList.Image src={item.imagesquare} style={{ minWidth: 125 }} />
                            <ResultList.Content>
                              <ResultList.Title
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              />
                              <ResultList.Description
                                dangerouslySetInnerHTML={{
                                  __html: item.abstract,
                                }}
                              />
                            </ResultList.Content>
                          </ResultList>
                      </div>
                    ))}
                  </ResultListWrapper>
                </div>
              )}
            </ReactiveList>
          </div>
        </ReactiveBase>
      </Fragment>
    );
  }
}

export default ReactiveSearch
